<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1200"
    @keydown.esc="dialog = false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="secondary" dense class="mx-1" v-bind="attrs" v-on="on">
        手動V3
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h4"> 手動V3 Task </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-btn color="primary darken-1" @click="packAndStartCustomerList()">
              列表
            </v-btn>
          </v-col>
          <v-col>
            <v-btn color="secondary darken-1" @click="packCustomerNormal()">
              Normal
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              color="default darken-1"
              @click="packCustomerTranscriptTask()"
            >
              TP only
            </v-btn>
          </v-col>
          <v-col>
            <v-btn color="green darken-1" @click="packCustomerFullTranscript()">
              Full TP
            </v-btn>
          </v-col>
          <v-col>
            <v-btn color="red darken-1" @click="packCopCreditorRegnoDiff()">
              COP 他項
            </v-btn>
          </v-col>
          <v-col>
            <v-btn color="yellow darken-1" @click="packCopOwnerRegnoDiff()">
              COP 所有權
            </v-btn>
          </v-col>
          <v-col>
            <v-btn color="blue darken-1" @click="packListQuery()">
              全列表
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="dialog = false">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: (vm) => ({
    dialog: false,
  }),
  props: { customer_mission_id: null, isHideExpand: false },

  components: {},
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    packAndStartCustomerList() {
      console.log("packAndStartCustomerList.");
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/system/schedule_v3/packAndStartCustomerList/";
      this.postScheduleAPI(url);
    },
    packCustomerNormal() {
      console.log("packCustomerNormal.");
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/system/schedule_v3/packCustomerNormal/";
      this.postScheduleAPI(url);
    },
    packCustomerTranscriptTask() {
      console.log("packCustomerTranscriptTask.");
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/system/schedule_v3/packCustomerTranscriptTask/";
      this.postScheduleAPI(url);
    },
    packCustomerFullTranscript() {
      console.log("packCustomerFullTranscript.");
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/system/schedule_v3/packCustomerFullTranscript/";
      this.postScheduleAPI(url);
    },
    packOwnerRegnoDiff() {
      console.log("packCustomerFullTranscript.");
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/system/schedule_v3/packOwnerRegnoDiff/";
      this.postScheduleAPI(url);
    },
    packCopOwnerRegnoDiff() {
      console.log("packCustomerFullTranscript.");
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/system/schedule_v3/packCopOwnerRegnoDiff/";
      this.postScheduleAPI(url);
    },
    packCopCreditorRegnoDiff() {
      console.log("packCopCreditorRegno.");
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/system/schedule_v3/packCopCreditorRegno/";
      this.postScheduleAPI(url);
    },
    packListQuery() {
      console.log("pack all query list.");
      const url =
        process.env.VUE_APP_SERVER_URL + "/system/schedule_v3/packListQuery/";
      this.postScheduleAPI(url);
    },
    postScheduleAPI(url) {
      const currentObj = this;
      let jsonData = {};
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      // console.log("json " + jsonData);
      this.axios
        .post(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.dialog = false;
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
  },
};
</script>
