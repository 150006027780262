<template>
  <v-container fluid tag="section">
    <confirm-dialog
      :isShow="confirmData.isConfirm"
      :title="confirmData.confirm_title"
      :message="confirmData.confirm_msg"
      :selectItem="confirmData.selectItem"
      :selectAction="confirmData.selectAction"
      v-on:onClose="onClose"
      v-on:onConfirm="onConfirm"
    ></confirm-dialog>
    <schedule-move-dialog
      :dialog_show="move_schedule.isShow"
      :origin="move_schedule.origin"
      v-on:onClose="onClose"
    ></schedule-move-dialog>
    <schedule-assign_V3
      :dialog_show="assign_schedule.isShow"
      :origin="assign_schedule.origin"
      :list="assign_schedule.accounts"
      :tp="assign_schedule.accounts_tp"
      v-on:onClose="onAssignDone"
    >
    </schedule-assign_V3>
    <v-dialog
      v-model="summary_show"
      max-width="80vw"
      @click:outside="summary_show = false"
      @keydown.esc="summary_show = false"
    >
      <v-card>
        <v-card-title>
          <span class="headline">任務</span>
        </v-card-title>

        <v-card-text>
          <v-data-table
            :headers="summary_headers"
            :items="summary_items"
            hide-default-footer
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="summary_show = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <base-material-card
      icon="mdi-clipboard-text"
      title="schedule"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        multi-sort
        :show-expand="!isHideExpand"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        @page-count="pageCount = $event"
      >
        <template v-slot:top>
          <v-row>
            <v-col cols="4" class="px-0 ma-0">
              <ScheduleManualDialog_V3></ScheduleManualDialog_V3>
              <ScheduleCheckDialog></ScheduleCheckDialog>
              <v-btn color="default" dense class="mx-1" @click="getSchedule"
                >更新</v-btn
              >
            </v-col>
            <v-col cols="2">
              <v-select
                :items="schedule_status"
                v-model="filterItem.status"
                label="狀態"
                outlined
                dense
                clearable
                @change="getSchedule"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-btn-toggle
                v-model="filterItem.status"
                @change="getScheduleClear"
                mandatory
                dense
                color="primary"
              >
                <v-btn
                  v-for="(item, index) in schedule_filter"
                  outlined
                  dense
                  :key="index"
                  v-show="item.disable != 1"
                >
                  {{ item.text }}
                </v-btn>

                <v-btn outlined> Customer </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <span class="yellow"
                >Yellow : No List and TP account
              </span></v-col
            >
            <v-col cols="2">
              <span class="grey"> Grey : No Account TP </span>
            </v-col>
            <v-col cols="2">
              <span class="primary"> Primary : No List Account: </span>
            </v-col>
          </v-row>
        </template>

        <template v-slot:item.id="{ item }">
          <span class="pa-2 secondary rounded-pill">{{ item.id }}</span>
        </template>
        <template v-slot:expanded-item="{ item }">
          <td :colspan="12">
            <mission-table
              :schedule_id="item.id"
              :read_only="true"
            ></mission-table>
          </td>
        </template>
        <template v-slot:item.type="{ item }">
          {{ item.type | getText(schedule_v3_type_str) }}
        </template>
        <template v-slot:item.description="{ item }">
          <span :class="getDescriptionColor(item)">
            {{ item.description }}</span
          >
        </template>

        <template v-slot:item.create_time="{ item }">{{
          item.create_time | moment("YYYY-MM-DD HH:mm:ss")
        }}</template>
        <template v-slot:item.update_time="{ item }">{{
          item.update_time | moment("YYYY-MM-DD HH:mm:ss")
        }}</template>

        <template v-slot:item.status="{ item }" v-if="isHideExpand">
          {{ item.status | getText(schedule_v3_status_str) }}
        </template>

        <template v-slot:item.status="{ item }" v-else>
          <v-edit-dialog @save="updateStatus(item)" large>
            {{ item.status | getText(schedule_v3_status_str) }}
            <template v-slot:input>
              <v-row align="baseline">
                <v-select
                  v-model="item.status"
                  :label="$t('status')"
                  :items="schedule_v3_status_str"
                  large
                ></v-select>
              </v-row>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom v-if="item.link != null">
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" v-on="on" @click="download(item)"
                >mdi-download-outline</v-icon
              >
            </template>
            <span>{{ $t("download") }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                class="mr-2"
                v-on="on"
                @click="confirmDeleteSchedule(item)"
                >mdi-delete-outline</v-icon
              >
            </template>
            <span>{{ $t("delete") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon class="mr-2" v-on="on" @click="onAssignSchedule(item)"
                >mdi-application-edit-outline</v-icon
              >
            </template>
            <span>{{ $t("assign") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon class="mr-2" v-on="on" @click="getSchedulesSummary(item)"
                >mdi-book-open-outline</v-icon
              >
            </template>
            <span>任務總結</span>
          </v-tooltip>
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                class="mr-2"
                v-on="on"
                @click="openMoveScheduleDialog(item)"
                >mdi-folder-move-outline</v-icon
              >
            </template>
            <span>{{ $t("move") }}</span>
          </v-tooltip> -->
          <v-tooltip bottom v-if="is_staff && item.status == 10">
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" v-on="on" @click="verify(item)"
                >mdi-file-chart</v-icon
              >
            </template>
            <span>{{ $t("verify") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-pagination v-model="page" :length="pageCount"></v-pagination>
    </base-material-card>
    <v-snackbar v-model="snackbar" centered>
      {{ msg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import MissionTable from "./MissionTable";
import { mapState } from "vuex";
import {
  schedule_status,
  radioOption,
  mission_action,
  change_types,
  schedule_filter,
  action_type_enum_str,
  status_items,
  system_type_enum_str,
  schedule_v3_type,
  schedule_v3_type_str,
  schedule_v3_status,
  schedule_v3_status_str,
} from "@/definition.js";
import CommonUtil from "@/utils/commonutil.js";

import ScheduleCheckDialog from "../dialogs/ScheduleCheckDialog";
import ConfirmDialog from "../dialogs/ConfirmDialog";
import ScheduleCreateDialog from "../dialogs/ScheduleCreateDialog";
import ScheduleCreateUploadDialog from "../dialogs/ScheduleCreateUploadDialog";
import ScheduleManualDialog_V3 from "../dialogs/ScheduleManualDialog_V3";
import ScheduleMoveDialog from "../dialogs/ScheduleMoveDialog";
import ScheduleAssign_V3 from "../dialogs/ScheduleAssign_V3";

import FileUpload from "../dialogs/FileUpload";

export default {
  data: (vm) => ({
    summary_show: false,
    schedule_v3_type: schedule_v3_type,
    schedule_v3_type_str: schedule_v3_type_str,
    schedule_v3_status: schedule_v3_status,
    schedule_v3_status_str: schedule_v3_status_str,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    status_title: "狀態",
    confirmData: {
      isConfirm: false,
      confirm_title: "啟動排程",
      confirm_msg: "",
      selectItem: null,
      selectAction: 0,
    },
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [10, 20, 50],
    },
    filterItem: {
      status: 1,
    },
    items: [],
    totalItems: 0,
    loading: false,
    options: {},
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("customer_mission"),
        value: "customer_mission",
      },
      {
        sortable: true,
        text: vm.$i18n.t("schedule_id"),
        value: "id",
      },
      {
        sortable: false,
        text: vm.$i18n.t("description"),
        value: "description",
      },
      {
        sortable: false,
        text: vm.$i18n.t("type"),
        value: "type",
      },
      // {
      //   sortable: false,
      //   text: vm.$i18n.t("mission_action"),
      //   value: "mission_action",
      // },
      // {
      //   sortable: false,
      //   text: vm.$i18n.t("mission_change_type"),
      //   value: "mission_change_type",
      // },
      {
        sortable: false,
        text: vm.$i18n.t("status"),
        value: "status",
      },
      {
        sortable: true,
        text: vm.$i18n.t("create_time"),
        value: "create_time",
      },
      {
        sortable: false,
        text: vm.$i18n.t("update_time"),
        value: "update_time",
      },
      // {
      //   sortable: false,
      //   text: vm.$i18n.t("done_rate"),
      //   value: "done_rate",
      // },
      {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
        width: "10%",
      },
    ],
    type_items: [
      {
        text: vm.$i18n.t("System"),
        value: 0,
      },
      {
        text: vm.$i18n.t("Customer"),
        value: 1,
      },
    ],
    schedule_status: schedule_status,
    schedule_filter: schedule_filter,
    mission_action: mission_action,
    change_types: change_types,

    lbtype_option: [
      {
        text: vm.$i18n.t("Land"),
        value: "L",
      },
      {
        text: vm.$i18n.t("Building"),
        value: "B",
      },
    ],
    file_upload: false,
    schedule_id: null,
    snackbar: false,
    msg: "No file to download",
    fetching: false,
    move_schedule: {
      isShow: false,
      origin: 0,
    },
    assign_schedule: {
      isShow: false,
      origin: 0,
      accounts: null,
      accounts_tp: null,
    },
    //
    summary_items: [],
    summary_headers: [
      {
        sortable: false,
        text: "系統",
        value: "system",
      },
      {
        sortable: false,
        text: "類別",
        value: "key",
      },
      {
        sortable: false,
        text: "任務池",
        value: "InPool",
      },
      {
        sortable: false,
        text: "已打包",
        value: "Packed",
      },
      {
        sortable: false,
        text: "進行中",
        value: "Processing",
      },
      {
        sortable: false,
        text: "暫停",
        value: "Suspend",
      },
      {
        sortable: false,
        text: "完成",
        value: "Done",
      },
      {
        sortable: false,
        text: "失敗",
        value: "Fail",
      },
      {
        sortable: false,
        text: "失效key",
        value: "KeyInvalid",
      },
    ],
  }),
  props: { customer_mission_id: null, isHideExpand: false },
  watch: {
    options: {
      handler() {
        this.getSchedule();
      },
    },
  },
  components: {
    MissionTable,
    ScheduleCreateDialog,
    ScheduleCreateUploadDialog,
    ScheduleCheckDialog,
    FileUpload,
    ScheduleManualDialog_V3,
    ConfirmDialog,
    ScheduleMoveDialog,
    ScheduleAssign_V3,
  },
  computed: {
    ...mapState(["token", "is_staff"]),
  },
  methods: {
    getDescriptionColor(item) {
      if (item.accounts == null && item.accounts_tp == null) {
        return "yellow";
      } else if (item.accounts == null) {
        return "primary";
      } else if (item.accounts_tp == null) {
        return "grey";
      }

      return "transparent";
    },
    getScheduleClear() {
      this.options.page = 1;
      this.getSchedule();
    },
    getSchedule() {
      if (this.fetching == true) {
        return;
      }

      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = `${process.env.VUE_APP_SERVER_URL}/system/schedule_v3/`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          page: page,
          page_size: itemsPerPage,
        },
      };
      // get sort

      if (sortBy.length > 0) {
        const sortParas = CommonUtil.getSortParas(sortBy, sortDesc);
        config.params.ordering = sortParas;
        console.log("go.");
      }

      if (!this.$common_util.isEmpty(this.customer_mission_id)) {
        config.params["customer_mission__id"] = this.customer_mission_id;
      }
      if (this.filterItem.status != null) {
        if (this.filterItem.status == -1) {
          config.params["status__lt"] = 2;
        } else {
          if (this.filterItem.status == 11) {
            config.params["status__lt"] = 2;
            config.params["customer_mission__id__isnull"] = 0;
          } else {
            config.params["status"] = this.filterItem.status;
          }
        }
      }

      this.fetching = true;
      this.axios
        .get(url, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.items = response.data["results"];
          currentObj.totalItems = response.data["count"];
          // console.log(currentObj.items);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        })
        .finally(() => {
          currentObj.fetching = false;
        });
    },

    updateStatus(item) {
      const currentObj = this;
      let url = null;
      switch (item.status) {
        case schedule_v3_status.Ongoing:
          console.log("start the schedule");
          url = `${process.env.VUE_APP_SERVER_URL}/system/schedule_v3/${item.id}/start/`;
          break;
        default:
          console.log("stop the schedule ");
          url = `${process.env.VUE_APP_SERVER_URL}/system/schedule_v3/${item.id}/stop/`;
          break;
      }
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      this.axios
        .post(url, {}, config)
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    showUploadDialog(item) {
      this.schedule_id = item.id;
      this.file_upload = true;
    },
    onCloseFile() {
      this.schedule_id = null;
      this.file_upload = false;
    },
    download(item) {
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/system/schedule_v3/" +
        item.id +
        "/download/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          console.log("download by link schedule xlsx");
          console.log(response.data);
          currentObj.forceFileDownload(response.data);

          // currentObj.getSchedule();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    forceFileDownload(response) {
      let filename = response.filename;
      if (filename != null) {
        const link = document.createElement("a");
        link.href = response.link;
        link.setAttribute("download", filename); //or any other extension
        document.body.appendChild(link);
        link.click();
      } else {
        this.snackbar = true;
      }
    },
    // start schedule
    confirmStartSchedule(item) {
      this.confirmData.selectItem = item;
      this.confirmData.selectAction = item.mission_action;
      this.confirmData.confirm_msg = `啟動任務: ${item.description}`;
      this.confirmData.isConfirm = true;
    },
    onClose() {
      this.confirmData.isConfirm = false;
      this.confirmData.confirm_msg = "";
      this.confirmData.selectAction = 0;
      this.confirmData.selectItem = null;
      this.move_schedule.isShow = false;
      this.move_schedule.origin = 0;
      this.assign_schedule.isShow = false;
      this.assign_schedule.origin = 0;
      this.assign_schedule.accounts = null;
      this.assign_schedule.accounts_tp = null;
    },
    onConfirm(data) {
      let _action = data.action;
      let _item = data.item;
      console.log(`Action ${_action}`);
      switch (_action) {
        case 999:
          console.log(`Remove the schedule ${_item.id}`);
          this.doDeleteSchedule(_item);
          break;
        default:
          this.startSchedule(_item);
      }

      this.onClose();
    },
    startSchedule(item) {
      const currentObj = this;
      const url = `${process.env.VUE_APP_SERVER_URL}/system/schedule_v3/${item.id}/start/`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };

      const jsonData = {};
      this.axios
        .post(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
          // currentObj.onClose();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    // delete  schedule
    confirmDeleteSchedule(item) {
      this.confirmData.selectItem = item;
      this.confirmData.selectAction = 999;
      this.confirmData.confirm_msg = `刪除任務: ${item.description}`;
      this.confirmData.isConfirm = true;
    },
    doDeleteSchedule(item) {
      const currentObj = this;
      const url = `${process.env.VUE_APP_SERVER_URL}/system/schedule_v3/${item.id}/`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };

      this.axios
        .delete(url, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.getSchedule();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    clearSchedule() {
      const currentObj = this;
      const url = `${process.env.VUE_APP_SERVER_URL}/system/schedule_v3/clearSchedules/`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };

      const jsonData = {};
      this.axios
        .post(url, jsonData, config)
        .then(function (response) {
          currentObj.getSchedule();
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    openMoveScheduleDialog(item) {
      this.move_schedule.origin = item.id;
      this.move_schedule.isShow = true;
    },
    // On Assign click
    onAssignSchedule(item) {
      console.log("on schedule assign", item.id);
      this.assign_schedule.origin = item.id;
      this.assign_schedule.isShow = true;

      this.assign_schedule.accounts = item.accounts;
      this.assign_schedule.accounts_tp = item.accounts_tp;
    },
    onAssignDone(item) {
      if (item != null) {
        this.getSchedule();
      }
      this.onClose();
    },
    nextSchedule() {
      const currentObj = this;
      const url = `${process.env.VUE_APP_SERVER_URL}/system/schedule_v3/nextSchedule/`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.getSchedule();
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },

    getSchedulesSummary(item) {
      console.log("on schedule assign", item.id);
      const currentObj = this;
      const url = `${process.env.VUE_APP_SERVER_URL}/system/schedule_v3/${item.id}/summary/`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.parseSummary(response.data);
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    parseSummary(result) {
      let actions = result.actions;
      let new_data = [];
      for (let key in actions) {
        let tag = CommonUtil.getText(key, action_type_enum_str);
        // let new_action = { key:  };
        let system = actions[key]; //CommonUtil.getText(actions[key],system_type_enum_str);
        for (let key2 in system) {
          let new_action = {
            key: tag,
            system: CommonUtil.getText(key2, system_type_enum_str),
          };
          let action = system[key2];
          for (let key2 in action) {
            let value = action[key2];
            let new_key = CommonUtil.getText(key2, status_items);
            new_action[new_key] = value;
          }
          console.log("key2", key2, " action", action);
          new_data.push(new_action);
        }

        // new_data.push(new_action);
      }
      console.log("new data");
      console.log(new_data);
      this.summary_items = new_data;
      this.summary_show = true;
    },
    verify(item) {
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/system/schedule_v3/" +
        item.id +
        "/verify/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      const jsonData = {};
      this.axios
        .post(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
          let count = response.data.count;
          console.log("get count " + count);
          if (count == 0) {
            console.log("create report " + count);
            currentObj.createReport(item);
            alert("report產生中");
          } else {
            alert("有特殊字");
          }
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    createReport(item) {
      console.log(`create report ${item.id}`);
      const currentObj = this;
      const url = `${process.env.VUE_APP_SERVER_URL}/system/schedule_v3/${item.id}/genreport/`;
      let jsonData = {
        status: item.status,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      console.log("json " + jsonData);
      this.axios
        .post(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
  },
};
</script>
